'use client';

import { type LucideIcon } from 'lucide-react';

import {
    SidebarGroup,
    SidebarGroupLabel,
    SidebarMenu,
    SidebarMenuButton,
    SidebarMenuItem,
} from '../../ui_components/sidebar/sidebar';

import { NavLink, useLocation } from 'react-router-dom';

export function NavProjects({
    projects,
}: {
    projects: {
        name: string;
        url: string;
        icon: LucideIcon;
    }[];
}) {
    const location = useLocation();

    return (
        <SidebarGroup className="group-data-[collapsible=icon]:hidden">
            <SidebarGroupLabel>Reports</SidebarGroupLabel>
            <SidebarMenu>
                {projects.map((item) => {
                    const isActive = location.pathname === item.url;
                    return (
                        <SidebarMenuItem key={item.name}>
                            <SidebarMenuButton asChild>
                                <NavLink
                                    to={item.url}
                                    className={
                                        isActive ? 'bg-sidebar-accent' : ''
                                    }
                                >
                                    <item.icon />
                                    {item.name}
                                </NavLink>
                            </SidebarMenuButton>
                        </SidebarMenuItem>
                    );
                })}
            </SidebarMenu>
        </SidebarGroup>
    );
}
