// @ts-nocheck
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
    getDirectories,
    removeDirectory,
} from '../../features/directories/directories_service';
import { useObservable } from '@ngneat/react-rxjs';
import { activeService$ } from '../../features/user/user_repository';
import { token$ } from '../../features/auth/auth_repository';
import {
    getDocuments,
    removeDocument,
    uploadDocument,
} from '../../features/documents/documents_service';
import {
    currentPage$,
    documents$,
    pages$,
    setCurrentPage,
} from '../../features/documents/documents_repository';
import {
    CiCircleChevLeft,
    CiCircleChevRight,
    CiEdit,
    CiSettings,
    CiTrash,
} from 'react-icons/ci';
import { IconContext } from 'react-icons';
import {
    getDirectory,
    hasDirectories,
} from '../../features/directories/directories_repository';
import { askDirectory } from '../../features/ask_questions/ask_questions_service';
import {
    answer$,
    updateAnswer,
} from '../../features/ask_questions/ask_questions_repository';
import { FileUploader } from 'react-drag-drop-files';
import { Confirm } from '../../ui_components/confirm/confirm';
import { updateIsLoading } from '../../features/global_loader/global_loader_service';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '../../ui_components/table/table';
import {
    DropdownMenu,
    DropdownMenuTrigger,
    DropdownMenuContent,
    DropdownMenuItem,
} from '../../ui_components/dropdown_menu/dropdown_menu';
import { Input } from '../../ui_components/input/input';

import {
    Select,
    SelectTrigger,
    SelectValue,
    SelectContent,
    SelectItem,
} from '../../ui_components/select/select';
import { Button } from '../../ui_components/button/button';

import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from '../../ui_components/breadcrumbs/breadcrumbs';
import {
    CirclePlus,
    Trash2,
    Link2,
    SendHorizontal,
    LoaderCircle,
    SquareX,
    EllipsisVertical,
} from 'lucide-react';
import {
    Drawer,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerTitle,
    DrawerTrigger,
    DrawerClose,
    DrawerDescription,
} from '../../ui_components/drawer/drawer';
import { ScrollArea } from '../../ui_components/scroll_area/scroll_area';
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from '../../ui_components/alert_dialog/alert_dialog';

export function Directory() {
    const [documents] = useObservable(documents$);
    const [token] = useObservable(token$);
    const [answer] = useObservable(answer$);
    const [pages] = useObservable(pages$);
    let params = useParams();
    const [activeService] = useObservable(activeService$);
    const navigate = useNavigate();
    const [directory] = useObservable(getDirectory(params.directoryId));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [question, setQuestion] = useState('');
    const [limit, setLimit] = useState(10);

    const [page] = useObservable(currentPage$);

    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [isInProgress, setIsInProgress] = useState(false);

    const handleRemoveDocument = async (documentId) => {
        updateIsLoading(true);
        await removeDocument(
            {
                serviceId: activeService.service_id,
                directoryId: params.directoryId,
                documentId,
            },
            token
        );

        getDocuments(
            {
                serviceId: activeService.service_id,
                directoryId: params.directoryId,
                params: {
                    limit,
                    page,
                },
            },
            token
        );
        updateIsLoading(false);
    };

    const handleChangePage = async (page) => {
        setCurrentPage(page);
    };

    const handleAskDirectory = async () => {
        setIsModalOpen(true);
        updateAnswer({ answer: null, sources: [] });

        const isSuccess = await askDirectory(
            {
                serviceId: activeService.service_id,
                directoryId: params.directoryId,
                question,
            },
            token
        );
    };

    useEffect(() => {
        async function fetchData() {
            if (activeService) {
                if (!hasDirectories) {
                    await getDirectories(
                        {
                            serviceId: activeService.service_id,
                        },
                        token
                    );
                }
            }
        }
        fetchData();
    }, [params, activeService, token]);

    useEffect(() => {
        async function fetchData() {
            if (activeService) {
                getDocuments(
                    {
                        serviceId: activeService.service_id,
                        directoryId: params.directoryId,
                        params: {
                            limit,
                            page,
                        },
                    },
                    token
                );
            }
        }
        fetchData();
    }, [params, activeService, token, limit, page]);

    const fileTypes = ['txt', 'pdf', 'md', 'html'];

    const handleChange = async (file) => {
        setIsInProgress(true);

        await uploadDocument(
            {
                serviceId: activeService.service_id,
                directoryId: params.directoryId,
                data: file,
            },
            token
        );

        setIsInProgress(false);

        getDocuments(
            {
                serviceId: activeService.service_id,
                directoryId: params.directoryId,
                params: {
                    limit,
                    page,
                },
            },
            token
        );
    };

    const handleRemoveDirectory = async () => {
        updateIsLoading(true);

        await removeDirectory(
            {
                serviceId: activeService.service_id,
                directoryId: params.directoryId,
            },
            token
        );

        updateIsLoading(false);

        return navigate('/directories');
    };

    return (
        <article className="min-h-[100vh] flex-1 rounded-xl md:min-h-min border bg-card text-card-foreground shadow p-6">
            <Breadcrumb>
                <BreadcrumbList>
                    <BreadcrumbItem>
                        <Link to="/admin">Home</Link>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                    <BreadcrumbItem>
                        <Link to={'/directories'}>Directories</Link>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                    <BreadcrumbItem>
                        <BreadcrumbPage>
                            {directory && directory.name}
                        </BreadcrumbPage>
                    </BreadcrumbItem>
                </BreadcrumbList>
            </Breadcrumb>

            <header className="flex items-center justify-between mb-8">
                <h2 className="font-semibold tracking-tight text-xl flex flex-col space-y-1.5">
                    {directory && directory.name}
                </h2>

                <aside className={'flex items-center gap-2'}>
                    <DropdownMenu>
                        <DropdownMenuTrigger className={'md:hidden'}>
                            <EllipsisVertical />
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                            <DropdownMenuItem
                                onClick={async () => {
                                    setIsConfirmOpen(true);
                                }}
                            >
                                <Trash2 />
                                Remove directory
                            </DropdownMenuItem>
                            <DropdownMenuItem asChild={true}>
                                <Link
                                    className={'flex gap-2'}
                                    to={`/directories/${params.directoryId}/addCrawl`}
                                >
                                    <Link2 />
                                    Get from url
                                </Link>
                            </DropdownMenuItem>
                            <DropdownMenuItem asChild={true}>
                                <Link
                                    className={'flex gap-2'}
                                    to={`/directories/${params.directoryId}/add`}
                                >
                                    <CirclePlus />
                                    Add document
                                </Link>
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>

                    <Button
                        className={'hidden md:flex'}
                        variant={'secondary'}
                        onClick={async () => {
                            setIsConfirmOpen(true);
                        }}
                    >
                        <Trash2 />
                        Remove directory
                    </Button>
                    <Link
                        className={'hidden md:block'}
                        to={`/directories/${params.directoryId}/addCrawl`}
                    >
                        <Button variant={'secondary'}>
                            <Link2 />
                            Get from url
                        </Button>
                    </Link>
                    <Link
                        className={'hidden md:block'}
                        to={`/directories/${params.directoryId}/add`}
                    >
                        <Button>
                            <CirclePlus />
                            Add document
                        </Button>
                    </Link>

                    {directory && (
                        <AlertDialog open={isConfirmOpen}>
                            <AlertDialogContent>
                                <AlertDialogHeader>
                                    <AlertDialogTitle>
                                        {`Removing directory: ${directory.name}`}
                                    </AlertDialogTitle>
                                    <AlertDialogDescription>
                                        This action cannot be undone. This will
                                        permanently delete this directory and
                                        remove your data from our servers.
                                    </AlertDialogDescription>
                                </AlertDialogHeader>
                                <AlertDialogFooter>
                                    <AlertDialogCancel
                                        onClick={() => {
                                            setIsConfirmOpen(false);
                                        }}
                                    >
                                        Cancel
                                    </AlertDialogCancel>
                                    <AlertDialogAction
                                        onClick={() => {
                                            handleRemoveDirectory();
                                            setIsConfirmOpen(false);
                                        }}
                                    >
                                        Remove
                                    </AlertDialogAction>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialog>
                    )}
                </aside>
            </header>

            {!isInProgress && (
                <FileUploader
                    handleChange={handleChange}
                    name="file"
                    types={fileTypes}
                >
                    <form className="flex flex-col gap-4 border border-dashed p-8 mb-8 justify-center items-center">
                        <Button>Add documents</Button>
                        <p className={'text-sm text-muted-foreground'}>
                            or simply drag them over
                        </p>
                    </form>
                </FileUploader>
            )}

            {isInProgress && (
                <div
                    className={
                        'flex flex-col gap-4 border border-dashed p-8 mb-8 justify-center items-center'
                    }
                >
                    <LoaderCircle className={'animate-spin'} size={64} />
                </div>
            )}

            {directory && documents.length > 0 ? (
                <>
                    <div className={'rounded-md border'}>
                        <Table>
                            <TableHeader>
                                <TableRow className={'border-t-0'}>
                                    <TableHead>Documents name</TableHead>
                                    <TableHead
                                        className={
                                            'hidden w-[140px] md:table-cell'
                                        }
                                    >
                                        Create date
                                    </TableHead>
                                    <TableHead
                                        className={'hidden md:table-cell'}
                                    >
                                        Link
                                    </TableHead>
                                    <TableHead className={'text-right'}>
                                        Actions
                                    </TableHead>
                                </TableRow>
                            </TableHeader>

                            <TableBody>
                                {directory &&
                                    documents.map((document) => (
                                        <TableRow key={document.id}>
                                            <TableCell>
                                                <Link
                                                    className={'link'}
                                                    to={`/directories/${directory.id}/document/${document.id}`}
                                                >
                                                    {document.name ? (
                                                        document.name
                                                    ) : (
                                                        <div
                                                            className={
                                                                'flex gap-2 items-center'
                                                            }
                                                        >
                                                            <LoaderCircle
                                                                className={
                                                                    'animate-spin'
                                                                }
                                                                size={24}
                                                            />{' '}
                                                            Processing...
                                                        </div>
                                                    )}
                                                </Link>
                                            </TableCell>
                                            <TableCell
                                                className={
                                                    'hidden md:table-cell'
                                                }
                                            >
                                                {new Intl.DateTimeFormat(
                                                    'en-US'
                                                ).format(
                                                    new Date(
                                                        document.createDate
                                                    )
                                                )}
                                            </TableCell>
                                            <TableCell
                                                className={
                                                    'hidden md:table-cell'
                                                }
                                            >
                                                {document.url}
                                            </TableCell>
                                            <TableCell className={'text-right'}>
                                                <DropdownMenu>
                                                    <DropdownMenuTrigger>
                                                        <IconContext.Provider
                                                            value={{
                                                                color: '#fff',
                                                                size: '1.5rem',
                                                                title: 'Actions',
                                                            }}
                                                        >
                                                            <span className="btn-icon">
                                                                <CiSettings aria-label="Actions" />
                                                            </span>
                                                        </IconContext.Provider>
                                                    </DropdownMenuTrigger>
                                                    <DropdownMenuContent>
                                                        <DropdownMenuItem>
                                                            <Link
                                                                className="flex items-center gap-2"
                                                                to={`/directories/${directory.id}/document/${document.id}`}
                                                            >
                                                                <IconContext.Provider
                                                                    value={{
                                                                        color: '#fff',
                                                                        size: '1.5rem',
                                                                        title: 'Edit',
                                                                    }}
                                                                >
                                                                    <CiEdit aria-label="Edit" />
                                                                </IconContext.Provider>
                                                                Edit
                                                            </Link>
                                                        </DropdownMenuItem>
                                                        <DropdownMenuItem>
                                                            <span
                                                                className="flex items-center gap-2"
                                                                onClick={() => {
                                                                    handleRemoveDocument(
                                                                        document.id
                                                                    );
                                                                }}
                                                            >
                                                                <IconContext.Provider
                                                                    value={{
                                                                        color: '#fff',
                                                                        size: '1.5rem',
                                                                        title: 'Remove',
                                                                    }}
                                                                >
                                                                    <CiTrash aria-label="Remove" />
                                                                </IconContext.Provider>
                                                                Remove
                                                            </span>
                                                        </DropdownMenuItem>
                                                    </DropdownMenuContent>
                                                </DropdownMenu>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </div>

                    <div className={'flex items-center justify-end px-2 mt-4'}>
                        <form
                            className={
                                'flex flex-col md:flex-row items-end gap-2 justify-end md:items-center md:gap-4 md:justify-end'
                            }
                        >
                            <div>
                                <label
                                    className={
                                        'flex items-center gap-2 text-sm font-medium'
                                    }
                                    htmlFor={'limit'}
                                >
                                    Show:
                                    <Select
                                        value={limit}
                                        onValueChange={(value) => {
                                            setLimit(value);

                                            setCurrentPage(1);
                                        }}
                                    >
                                        <SelectTrigger className={'h-8'}>
                                            <SelectValue>{limit}</SelectValue>
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectItem value={10}>
                                                10
                                            </SelectItem>
                                            <SelectItem value={20}>
                                                20
                                            </SelectItem>
                                            <SelectItem value={50}>
                                                50
                                            </SelectItem>
                                        </SelectContent>
                                    </Select>
                                </label>
                            </div>

                            <div
                                className={
                                    'flex items-center gap-2 text-sm font-medium'
                                }
                            >
                                {page > 1 && (
                                    <div
                                        onClick={() => {
                                            handleChangePage(page - 1);
                                        }}
                                    >
                                        <IconContext.Provider
                                            value={{
                                                color: '#fff',
                                                size: '1.5rem',
                                                title: 'Actions',
                                            }}
                                        >
                                            <span className="btn-icon">
                                                <CiCircleChevLeft aria-label="Previous page" />
                                            </span>
                                        </IconContext.Provider>
                                    </div>
                                )}
                                <div>
                                    <label
                                        className={
                                            'flex items-center gap-2 text-sm font-medium'
                                        }
                                        htmlFor={'limit'}
                                    >
                                        Page:
                                        <Input
                                            className={'h-8'}
                                            type={'number'}
                                            value={page}
                                            max={pages}
                                            min={1}
                                            onChange={(ev) =>
                                                setCurrentPage(ev.target.value)
                                            }
                                        />
                                    </label>
                                </div>
                                of {pages}
                                {page < pages && (
                                    <div
                                        onClick={() => {
                                            handleChangePage(page + 1);
                                        }}
                                    >
                                        <IconContext.Provider
                                            value={{
                                                color: '#fff',
                                                size: '1.5rem',
                                                title: 'Actions',
                                            }}
                                        >
                                            <span className="btn-icon">
                                                <CiCircleChevRight aria-label="Next page" />
                                            </span>
                                        </IconContext.Provider>
                                    </div>
                                )}
                            </div>
                        </form>
                    </div>

                    <div className="flex gap-2 mt-4 sticky py-4 bottom-0 bg-background">
                        <Input
                            onChange={(ev) => {
                                setQuestion(ev.target.value);
                            }}
                            onKeyDown={(ev) => {
                                if (ev.code === 'Enter') {
                                    setQuestion(ev.target.value);
                                    handleAskDirectory(directory.id);
                                }
                            }}
                            size={20}
                            className={'p-5'}
                            placeholder={'Ask me about this directory'}
                            type="text"
                        />
                        <Button
                            size={'icon'}
                            onClick={() => handleAskDirectory(directory.id)}
                        >
                            <SendHorizontal />
                        </Button>
                    </div>

                    <Drawer open={isModalOpen}>
                        <DrawerContent
                            className={
                                'flex items-center justify-end max-h-dvh'
                            }
                        >
                            <div className={'max-w-full w-[800px]'}>
                                <DrawerHeader className={'flex w-full'}>
                                    <DrawerTitle>{question}</DrawerTitle>
                                </DrawerHeader>

                                {answer.answer && (
                                    <ScrollArea className="h-[50dvh]">
                                        <div className="p-4 pb-0">
                                            <div
                                                className={
                                                    'text-base mb-8 leading-relaxed text-muted-foreground'
                                                }
                                                dangerouslySetInnerHTML={{
                                                    // eslint-disable-next-line no-undef
                                                    __html: marked.parse(
                                                        answer.answer
                                                    ),
                                                }}
                                            ></div>
                                            <br />
                                            <h3
                                                className={
                                                    'font-semibold leading-none tracking-tight'
                                                }
                                            >
                                                Source context
                                            </h3>
                                            <br />
                                            <ul>
                                                {answer.sources.map(
                                                    (source) => (
                                                        <li key={source.id}>
                                                            <a
                                                                className={
                                                                    'text-xs font-normal leading-snug text-muted-foreground'
                                                                }
                                                                target={
                                                                    '_blank'
                                                                }
                                                                href={
                                                                    source.url
                                                                }
                                                            >
                                                                {source.name}
                                                            </a>
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                    </ScrollArea>
                                )}

                                {!answer.answer && (
                                    <div
                                        className={
                                            'flex items-center justify-center'
                                        }
                                    >
                                        <LoaderCircle
                                            className={'animate-spin'}
                                            size={128}
                                        />
                                    </div>
                                )}

                                <DrawerFooter>
                                    <div className="flex gap-2 mt-4">
                                        <Input
                                            onChange={(ev) => {
                                                setQuestion(ev.target.value);
                                            }}
                                            onKeyDown={(ev) => {
                                                if (ev.code === 'Enter') {
                                                    setQuestion(
                                                        ev.target.value
                                                    );
                                                    handleAskDirectory(
                                                        directory.id
                                                    );
                                                }
                                            }}
                                            size={20}
                                            className={'p-5 w-auto flex-auto'}
                                            placeholder={
                                                'Ask me about this directory'
                                            }
                                            type="text"
                                        />
                                        <Button
                                            size={'icon'}
                                            onClick={() =>
                                                handleAskDirectory(directory.id)
                                            }
                                        >
                                            <SendHorizontal />
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                setIsModalOpen(false)
                                            }
                                            variant="secondary"
                                        >
                                            Close
                                        </Button>
                                    </div>
                                </DrawerFooter>
                            </div>
                        </DrawerContent>
                    </Drawer>
                </>
            ) : (
                <div className={'text-center max-w-3xl mx-auto'}>
                    <h3
                        className={
                            'font-semibold leading-none tracking-tight mb-2'
                        }
                    >
                        You don't have any documents yet!
                    </h3>
                    <p className={'text-sm text-muted-foreground'}>
                        Upload your documents by dragging them into the field
                        above,{' '}
                        <Link
                            className={'link link_secondary'}
                            to={`/directories/${params.directoryId}/addCrawl`}
                        >
                            crawl your sites
                        </Link>
                        , or simply{' '}
                        <Link
                            className={'link link_secondary'}
                            to={`/directories/${params.directoryId}/add`}
                        >
                            add them as plain text
                        </Link>{' '}
                        to provide knowledge. This will enable Gandy to assist
                        you with your data and allow you to ask any questions
                        about the knowledge you provide.
                    </p>
                </div>
            )}
        </article>
    );
}
