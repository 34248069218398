// @ts-nocheck
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useObservable } from '@ngneat/react-rxjs';
import { activeService$ } from '../../features/user/user_repository';
import { token$ } from '../../features/auth/auth_repository';
import {
    createToken,
    disableToken,
    enableToken,
    readTokens,
    removeToken,
} from '../../features/rest_api/rest_api_service';
import { restApiTokens$ } from '../../features/rest_api/rest_api_repository';

import { IconContext } from 'react-icons';
import { CiEdit, CiSettings, CiTrash } from 'react-icons/ci';
import { Link } from 'react-router-dom';
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from '../../ui_components/breadcrumbs/breadcrumbs';
import { CirclePlus } from 'lucide-react';
import { Button } from '../../ui_components/button/button';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '../../ui_components/table/table';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '../../ui_components/dropdown_menu/dropdown_menu';
import {
    AlertDialogDescription,
    AlertDialogHeader,
    AlertDialogTitle,
} from '../../ui_components/alert_dialog/alert_dialog';
import { Input } from '../../ui_components/input/input';
import {
    AlertDialog,
    AlertDialogContent,
} from '../../ui_components/alert_dialog/alert_dialog';
import { Textarea } from '../../ui_components/textarea/textarea';
import {
    Alert,
    AlertDescription,
    AlertTitle,
} from '../../ui_components/alert/alert';
import { AlertCircle } from 'lucide-react';

export function RestApi() {
    const [activeService] = useObservable(activeService$);
    const [token] = useObservable(token$);
    const [restApiTokens] = useObservable(restApiTokens$);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newTokenName, setNewTokenName] = useState('');
    const [restApiToken, setRestApiToken] = useState(null);

    const createNewToken = async (event) => {
        event.preventDefault();

        setIsModalOpen(false);

        const restApiTokenResponse = await createToken(
            {
                name: newTokenName,
                serviceId: activeService.service_id,
            },
            token
        );

        setRestApiToken(restApiTokenResponse.token);
        readTokens({ serviceId: activeService.service_id }, token);
    };

    const showCreateNewTokenModal = () => {
        setIsModalOpen(true);
    };

    useEffect(() => {
        async function fetchData() {
            if (activeService) {
                readTokens({ serviceId: activeService.service_id }, token);
            }
        }
        fetchData();
    }, [activeService, token]);

    return (
        <article className="min-h-[100vh] flex-1 rounded-xl md:min-h-min rounded-xl border bg-card text-card-foreground shadow p-6">
            <Breadcrumb>
                <BreadcrumbList>
                    <BreadcrumbItem>
                        <Link to="/admin">Home</Link>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                    <BreadcrumbItem>
                        <Link to={'/integrations'}>Integrations</Link>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                    <BreadcrumbItem>
                        <BreadcrumbPage>Rest Api</BreadcrumbPage>
                    </BreadcrumbItem>
                </BreadcrumbList>
            </Breadcrumb>

            <header className="flex items-center justify-between mb-8">
                <h2 className="font-semibold tracking-tight text-xl flex flex-col space-y-1.5">
                    Tokens
                </h2>

                <aside className={'flex items-center gap-2'}>
                    <Button
                        onClick={async () => {
                            showCreateNewTokenModal();
                        }}
                    >
                        <CirclePlus />
                        Create token
                    </Button>
                </aside>
            </header>

            {restApiToken && (
                <div className={'mb-2'}>
                    <Alert
                        className={'border-yellow-400 text-yellow-400'}
                        variant={'default'}
                    >
                        <AlertCircle className="h-4 w-4  stroke-yellow-400" />
                        <AlertTitle className={'font-semibold'}>
                            Copy your token!
                        </AlertTitle>
                        <AlertDescription>
                            Make sure to copy your personal access token now.
                            You won’t be able to see it again!
                        </AlertDescription>
                    </Alert>

                    <Input
                        className={'mt-2'}
                        type={'text'}
                        defaultValue={restApiToken}
                    />
                </div>
            )}

            {restApiTokens && restApiTokens.length > 0 && (
                <div className={'rounded-md border'}>
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHead>Name</TableHead>
                                <TableHead className={'text-right'}>
                                    Actions
                                </TableHead>
                            </TableRow>
                        </TableHeader>

                        <TableBody>
                            {restApiTokens.map((restApiToken) => (
                                <TableRow key={restApiToken.token_id}>
                                    <TableCell
                                        className={
                                            restApiToken.disabled
                                                ? 'text-red-600'
                                                : 'text-green-600'
                                        }
                                    >
                                        {restApiToken.name}{' '}
                                        {restApiToken.disabled && '(disabled)'}
                                    </TableCell>
                                    <TableCell className={'text-right'}>
                                        <DropdownMenu>
                                            <DropdownMenuTrigger>
                                                <IconContext.Provider
                                                    value={{
                                                        color: '#fff',
                                                        size: '1.5rem',
                                                        title: 'Actions',
                                                    }}
                                                >
                                                    <span className="btn-icon">
                                                        <CiSettings aria-label="Actions" />
                                                    </span>
                                                </IconContext.Provider>
                                            </DropdownMenuTrigger>
                                            <DropdownMenuContent>
                                                <DropdownMenuItem>
                                                    <>
                                                        {restApiToken.disabled ===
                                                            false && (
                                                            <span
                                                                onClick={async () => {
                                                                    await disableToken(
                                                                        {
                                                                            serviceId:
                                                                                activeService.service_id,
                                                                            tokenId:
                                                                                restApiToken.token_id,
                                                                            name: restApiToken.name,
                                                                        },
                                                                        token
                                                                    );

                                                                    readTokens(
                                                                        {
                                                                            serviceId:
                                                                                activeService.service_id,
                                                                        },
                                                                        token
                                                                    );
                                                                }}
                                                                className={
                                                                    'flex gap-2'
                                                                }
                                                            >
                                                                <IconContext.Provider
                                                                    value={{
                                                                        color: '#fff',
                                                                        size: '1.5rem',
                                                                        title: 'Disable',
                                                                    }}
                                                                >
                                                                    <CiEdit aria-label="Disable" />
                                                                </IconContext.Provider>
                                                                Disable
                                                            </span>
                                                        )}

                                                        {restApiToken.disabled ===
                                                            true && (
                                                            <span
                                                                onClick={async () => {
                                                                    await enableToken(
                                                                        {
                                                                            serviceId:
                                                                                activeService.service_id,
                                                                            tokenId:
                                                                                restApiToken.token_id,
                                                                            name: restApiToken.name,
                                                                        },
                                                                        token
                                                                    );

                                                                    readTokens(
                                                                        {
                                                                            serviceId:
                                                                                activeService.service_id,
                                                                        },
                                                                        token
                                                                    );
                                                                }}
                                                                className={
                                                                    'flex gap-2 items-center'
                                                                }
                                                            >
                                                                <IconContext.Provider
                                                                    value={{
                                                                        color: '#fff',
                                                                        size: '1.5rem',
                                                                        title: 'Enable',
                                                                    }}
                                                                >
                                                                    <CiEdit aria-label="Enable" />
                                                                </IconContext.Provider>
                                                                Enable
                                                            </span>
                                                        )}
                                                    </>
                                                </DropdownMenuItem>
                                                <DropdownMenuItem>
                                                    <>
                                                        <span
                                                            onClick={async () => {
                                                                await removeToken(
                                                                    {
                                                                        serviceId:
                                                                            activeService.service_id,
                                                                        tokenId:
                                                                            restApiToken.token_id,
                                                                    },
                                                                    token
                                                                );
                                                                readTokens(
                                                                    {
                                                                        serviceId:
                                                                            activeService.service_id,
                                                                    },
                                                                    token
                                                                );
                                                            }}
                                                            className={
                                                                'flex gap-2 items-center'
                                                            }
                                                        >
                                                            <IconContext.Provider
                                                                value={{
                                                                    color: '#fff',
                                                                    size: '1.5rem',
                                                                    title: 'Enable',
                                                                }}
                                                            >
                                                                <CiTrash aria-label="Remove" />
                                                            </IconContext.Provider>
                                                            Remove
                                                        </span>
                                                    </>
                                                </DropdownMenuItem>
                                            </DropdownMenuContent>
                                        </DropdownMenu>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            )}

            <AlertDialog open={isModalOpen}>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>
                            {`Enter token name`}
                        </AlertDialogTitle>
                        <AlertDialogDescription>
                            This will create new token
                        </AlertDialogDescription>
                    </AlertDialogHeader>

                    <form
                        className="flex gap-2 items-stretch"
                        onSubmit={createNewToken}
                    >
                        <Input
                            type={'text'}
                            onChange={(e) => setNewTokenName(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Escape') {
                                    setNewTokenName(e.target.value);
                                    createNewToken();
                                }
                            }}
                        />
                        <Button size={'sm'}>Create new</Button>
                    </form>
                </AlertDialogContent>
            </AlertDialog>
        </article>
    );
}
