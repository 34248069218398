'use client';

import * as React from 'react';
import {
    BookOpen,
    Bot,
    Frame,
    PieChart,
    SeparatorHorizontal,
} from 'lucide-react';

import {
    Sidebar,
    SidebarContent,
    SidebarFooter,
    SidebarHeader,
    SidebarRail,
} from '../../ui_components/sidebar/sidebar';
import { TeamSwitcher } from './team_switcher';
import { NavMain } from './nav_main';
import { NavProjects } from './nav_projects';
import { NavUser } from './nav_user';
import { useObservable } from '@ngneat/react-rxjs';
import { user$ } from '../../features/user/user_repository';
import Usage from '../../ui_components/usage/usage';
import { Separator } from '../../ui_components/separator/separator';

// This is sample data.
const data = {
    user: {
        name: 'shadcn',
        email: 'm@example.com',
        avatar: '/avatars/shadcn.jpg',
    },
    navMain: [
        {
            name: 'Directories',
            url: '/directories',
            icon: BookOpen,
        },
        {
            name: 'Integrations',
            url: '/integrations',
            icon: Bot,
        },
    ],
    projects: [
        {
            name: 'Questions',
            url: '/questions',
            icon: Frame,
        },
        {
            name: 'Usage',
            url: '/usage',
            icon: PieChart,
        },
    ],
};

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
    const [user] = useObservable(user$);

    return (
        <Sidebar collapsible="icon" {...props}>
            <SidebarHeader>
                <NavUser />
            </SidebarHeader>
            <SidebarContent>
                <NavMain projects={data.navMain} />
                <NavProjects projects={data.projects} />
            </SidebarContent>
            <SidebarFooter>
                <Separator className={'my-1'} />
                <Usage />
                <Separator />
                <TeamSwitcher services={user.services} />
            </SidebarFooter>
            <SidebarRail />
        </Sidebar>
    );
}
