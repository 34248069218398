// @ts-nocheck
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    auth,
    logInWithEmailAndPassword,
    signInWithGoogle,
} from './firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';
import Logo from './ui_components/logo/logo';
import { LoadingScreen } from './pages/loading_screen/loading_screen';
import googleLogo from './img/google_logo.svg';
import { Button } from './ui_components/button/button';
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from './ui_components/card/card';
import { Input } from './ui_components/input/input';
import { Label } from './ui_components/label/label';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isIncorrectPasswordOrEmail, setIsIncorrectPasswordOrEmail] =
        useState(false);

    useEffect(() => {
        if (loading) {
            setIsLoading(true);
            return;
        } else {
            setIsLoading(false);
        }

        if (user) navigate('/admin');
    }, [user, loading, navigate]);

    const login = async () => {
        setIsIncorrectPasswordOrEmail(false);
        const loginSuccessful = await logInWithEmailAndPassword(
            email,
            password
        );

        if (loginSuccessful === null) {
            setIsIncorrectPasswordOrEmail(true);
        }
    };

    return !isLoading ? (
        <div className="w-dvw h-dvh flex flex-col items-center justify-center">
            <div className={'flex flex-col gap-6'}>
                <Card>
                    <CardHeader>
                        <CardTitle className="text-2xl">Login</CardTitle>
                        <CardDescription>
                            Enter your email below to login to your account
                        </CardDescription>
                    </CardHeader>
                    <CardContent>
                        <form
                            onSubmit={(ev) => {
                                ev.preventDefault();
                                logInWithEmailAndPassword(email, password);
                            }}
                        >
                            <div className="flex flex-col gap-6">
                                {isIncorrectPasswordOrEmail && (
                                    <div className="text-red-500">
                                        Incorrect email or password
                                    </div>
                                )}

                                <div className="grid gap-2">
                                    <Label htmlFor="email">Email</Label>
                                    <Input
                                        id="email"
                                        type="email"
                                        value={email}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        placeholder="m@example.com"
                                        autoComplete={'email'}
                                    />
                                </div>

                                <div className="grid gap-2">
                                    <div className="flex items-center">
                                        <Label htmlFor="password">
                                            Password
                                        </Label>

                                        <Link
                                            className={
                                                'ml-auto inline-block text-sm underline-offset-4 hover:underline'
                                            }
                                            to="/reset"
                                        >
                                            Forgot your password?
                                        </Link>
                                    </div>
                                    <Input
                                        id="password"
                                        type="password"
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                        placeholder="Password"
                                        value={password}
                                    />
                                </div>
                                <Button
                                    type={'button'}
                                    onClick={login}
                                    className="w-full"
                                >
                                    Login
                                </Button>
                                <Button
                                    type={'button'}
                                    onClick={signInWithGoogle}
                                    variant="outline"
                                    className="w-full"
                                >
                                    <img
                                        src={googleLogo}
                                        height="24"
                                        width="24"
                                        alt="Google Logo"
                                    />
                                    Login with Google
                                </Button>
                            </div>
                        </form>
                    </CardContent>
                </Card>
            </div>
        </div>
    ) : (
        <LoadingScreen />
    );
}

export default Login;
