// @ts-nocheck
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useObservable } from '@ngneat/react-rxjs';
import { activeService$ } from '../../features/user/user_repository';
import { token$ } from '../../features/auth/auth_repository';
import {
    createDocument,
    readDocument,
    removeDocument,
    updateDocument,
} from '../../features/documents/documents_service';
import {
    activeDocument$,
    isActiveDocumentLoading$,
    setIsLoading,
} from '../../features/documents/documents_repository';
import {
    Dialog,
    DialogPanel,
    DialogTitle,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
} from '@headlessui/react';
import {
    answer$,
    updateAnswer,
} from '../../features/ask_questions/ask_questions_repository';
import { askDocument } from '../../features/ask_questions/ask_questions_service';
import { IconContext } from 'react-icons';
import { CiCircleChevLeft, CiCircleRemove, CiTrash } from 'react-icons/ci';
import { Confirm } from '../../ui_components/confirm/confirm';
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from '../../ui_components/breadcrumbs/breadcrumbs';
import { LoaderCircle, SendHorizontal, Trash2 } from 'lucide-react';
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from '../../ui_components/alert_dialog/alert_dialog';
import { Button } from '../../ui_components/button/button';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '../../ui_components/form/form';
import { Input } from '../../ui_components/input/input';
import { Textarea } from '../../ui_components/textarea/textarea';
import {
    Drawer,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerTitle,
} from '../../ui_components/drawer/drawer';
import { ScrollArea } from '../../ui_components/scroll_area/scroll_area';

const formSchema = z.object({
    documentName: z.string().nonempty('Username is required'),
    documentUrl: z.string().optional(),
    documentContent: z.string().nonempty('Document content is required'),
});

export function EditDocument() {
    const [token] = useObservable(token$);
    const [activeService] = useObservable(activeService$);
    const [activeDocument] = useObservable(activeDocument$);
    let params = useParams();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [question, setQuestion] = useState('');
    const [answer] = useObservable(answer$);
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const [isActiveDocumentLoading] = useObservable(isActiveDocumentLoading$);

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            documentName: activeDocument?.name || '',
            documentUrl: activeDocument?.url || '',
            documentContent: activeDocument?.content || '',
        },
    });

    const handleAskDocument = async () => {
        setIsModalOpen(true);
        updateAnswer({ answer: null, sources: [] });

        await askDocument(
            {
                serviceId: activeService.service_id,
                documentId: params.documentId,
                question,
            },
            token
        );
    };

    async function onSubmit(values: z.infer<typeof formSchema>) {
        const { documentName, documentUrl, documentContent } = values;

        await updateDocument(
            {
                serviceId: activeService.service_id,
                directoryId: params.directoryId,
                documentId: activeDocument.id,
                name: documentName,
                url: documentUrl,
                content: documentContent,
                mimetype: activeDocument.mimetype,
            },
            token
        );

        return navigate(`/directories/${params.directoryId}`);
    }

    const handleRemoveDocument = async (documentId) => {
        await removeDocument(
            {
                serviceId: activeService.service_id,
                directoryId: params.directoryId,
                documentId,
            },
            token
        );

        return navigate(`/directories/${params.directoryId}`);
    };

    useEffect(() => {
        if (activeService) {
            async function fetchData() {
                await readDocument(
                    {
                        serviceId: activeService.service_id,
                        directoryId: params.directoryId,
                        documentId: params.documentId,
                    },
                    token
                );
            }

            fetchData();
        }
    }, [activeService, params.directoryId, params.documentId, token]);

    useEffect(() => {
        form.setValue('documentName', activeDocument?.name || '');
        form.setValue('documentUrl', activeDocument?.url || '');
        form.setValue('documentContent', activeDocument?.content || '');
    }, [activeDocument]);

    return (
        <article className="flex-1 rounded-xl md:min-h-min border bg-card text-card-foreground shadow p-6">
            <Breadcrumb>
                <BreadcrumbList>
                    <BreadcrumbItem>
                        <Link to="/admin">Home</Link>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                    <BreadcrumbItem>
                        <Link to={`/directories/${params.directoryId}`}>
                            Directories
                        </Link>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                    <BreadcrumbItem>
                        <BreadcrumbPage>Edit document</BreadcrumbPage>
                    </BreadcrumbItem>
                </BreadcrumbList>
            </Breadcrumb>

            {isActiveDocumentLoading ? (
                <div className={'flex items-center justify-center'}>
                    <LoaderCircle className={'animate-spin'} size={128} />
                </div>
            ) : (
                <>
                    <header className="flex items-center justify-between mb-8 min-h-[40px]">
                        <h2 className="font-semibold tracking-tight text-xl flex flex-col space-y-1.5">
                            Edit document
                        </h2>

                        <aside className={'flex items-center gap-2'}>
                            <Button
                                variant={'secondary'}
                                onClick={() => {
                                    setIsConfirmOpen(true);
                                }}
                            >
                                <Trash2 />
                                Remove
                            </Button>

                            <AlertDialog open={isConfirmOpen}>
                                <AlertDialogContent>
                                    <AlertDialogHeader>
                                        <AlertDialogTitle>
                                            {`Removing document: ${activeDocument?.name || ''}`}
                                        </AlertDialogTitle>
                                        <AlertDialogDescription>
                                            This action cannot be undone. This
                                            will permanently delete this
                                            document and remove your data from
                                            our servers.
                                        </AlertDialogDescription>
                                    </AlertDialogHeader>
                                    <AlertDialogFooter>
                                        <AlertDialogCancel
                                            onClick={() => {
                                                setIsConfirmOpen(false);
                                            }}
                                        >
                                            Cancel
                                        </AlertDialogCancel>
                                        <AlertDialogAction
                                            onClick={() => {
                                                handleRemoveDocument(
                                                    params.documentId
                                                );
                                                setIsConfirmOpen(false);
                                            }}
                                        >
                                            Remove
                                        </AlertDialogAction>
                                    </AlertDialogFooter>
                                </AlertDialogContent>
                            </AlertDialog>
                        </aside>
                    </header>

                    {activeDocument && (
                        <Form {...form}>
                            <form
                                onSubmit={form.handleSubmit(onSubmit)}
                                className="space-y-6 max-w-3xl mx-auto"
                            >
                                <FormField
                                    control={form.control}
                                    name="documentName"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Document name</FormLabel>
                                            <FormControl>
                                                <Input
                                                    disabled={
                                                        activeDocument.mimetype ===
                                                        'web/crawled'
                                                    }
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="documentUrl"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Document url</FormLabel>
                                            <FormControl>
                                                <Input
                                                    disabled={
                                                        activeDocument.mimetype ===
                                                        'web/crawled'
                                                    }
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                {activeDocument.mimetype === 'text/plain' && (
                                    <FormField
                                        control={form.control}
                                        name="documentContent"
                                        render={({ field }) => (
                                            <FormItem>
                                                <FormLabel>
                                                    Document content
                                                </FormLabel>
                                                <FormControl>
                                                    <Textarea
                                                        disabled={
                                                            activeDocument.mimetype ===
                                                            'web/crawled'
                                                        }
                                                        {...field}
                                                    />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                )}

                                {activeDocument.mimetype !== 'web/crawled' && (
                                    <Button type="submit">Save document</Button>
                                )}
                            </form>
                        </Form>
                    )}

                    <br />
                    <br />

                    <div className="flex gap-2 mt-4 sticky py-4 bottom-0 bg-background">
                        <Input
                            onChange={(ev) => {
                                setQuestion(ev.target.value);
                            }}
                            onKeyDown={(ev) => {
                                if (ev.code === 'Enter') {
                                    setQuestion(ev.target.value);
                                    handleAskDocument();
                                }
                            }}
                            size={20}
                            className={'p-5'}
                            placeholder={'Ask me about this file'}
                            type="text"
                        />
                        <Button
                            onClick={() => handleAskDocument()}
                            size={'icon'}
                        >
                            <SendHorizontal />
                        </Button>
                    </div>

                    <Drawer open={isModalOpen}>
                        <DrawerContent
                            className={
                                'flex items-center justify-end max-h-dvh'
                            }
                        >
                            <div className={'max-w-full w-[800px]'}>
                                <DrawerHeader className={'flex w-full'}>
                                    <DrawerTitle>{question}</DrawerTitle>
                                </DrawerHeader>

                                {answer.answer && (
                                    <ScrollArea className="h-[50dvh]">
                                        <div className="p-4 pb-0">
                                            <div
                                                className={
                                                    'text-base mb-8 leading-relaxed text-muted-foreground'
                                                }
                                                dangerouslySetInnerHTML={{
                                                    // eslint-disable-next-line no-undef
                                                    __html: marked.parse(
                                                        answer.answer
                                                    ),
                                                }}
                                            ></div>
                                            <br />
                                            <h3
                                                className={
                                                    'font-semibold leading-none tracking-tight'
                                                }
                                            >
                                                Source context
                                            </h3>
                                            <br />
                                            <ul>
                                                {answer.sources.map(
                                                    (source) => (
                                                        <li key={source.id}>
                                                            <a
                                                                className={
                                                                    'text-xs font-normal leading-snug text-muted-foreground'
                                                                }
                                                                target={
                                                                    '_blank'
                                                                }
                                                                href={
                                                                    source.url
                                                                }
                                                            >
                                                                {source.name}
                                                            </a>
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                    </ScrollArea>
                                )}

                                {!answer.answer && (
                                    <div
                                        className={
                                            'flex items-center justify-center'
                                        }
                                    >
                                        <LoaderCircle
                                            className={'animate-spin'}
                                            size={128}
                                        />
                                    </div>
                                )}

                                <DrawerFooter>
                                    <div className="flex gap-2 mt-4">
                                        <Input
                                            onChange={(ev) => {
                                                setQuestion(ev.target.value);
                                            }}
                                            onKeyDown={(ev) => {
                                                if (ev.code === 'Enter') {
                                                    setQuestion(
                                                        ev.target.value
                                                    );
                                                    handleAskDirectory(
                                                        directory.id
                                                    );
                                                }
                                            }}
                                            size={20}
                                            className={'p-5 w-auto flex-auto'}
                                            placeholder={
                                                'Ask me about this directory'
                                            }
                                            type="text"
                                        />
                                        <Button
                                            size={'icon'}
                                            onClick={() =>
                                                handleAskDirectory(directory.id)
                                            }
                                        >
                                            <SendHorizontal />
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                setIsModalOpen(false)
                                            }
                                            variant="secondary"
                                        >
                                            Close
                                        </Button>
                                    </div>
                                </DrawerFooter>
                            </div>
                        </DrawerContent>
                    </Drawer>
                </>
            )}
        </article>
    );
}
