//@ts-nocheck

import React, { useEffect, useState } from 'react';
import { getSlackConnection } from '../../features/slack/slack_service';
import { useObservable } from '@ngneat/react-rxjs';
import { token$ } from '../../features/auth/auth_repository';
import { activeService$ } from '../../features/user/user_repository';
import { Link } from 'react-router-dom';
import slackLogo from '../../img/slack_icon.svg';
import restApiIcon from '../../img/rest_api_icon.svg';
import { Button } from '../../ui_components/button/button';

export function Integrations() {
    const [token] = useObservable(token$);
    const [activeService] = useObservable(activeService$);
    const [isConnected, setIsConnected] = useState(false);

    useEffect(() => {
        if (activeService) {
            const fetchData = async () => {
                const slackConnectionStatus = await getSlackConnection(
                    { serviceId: activeService.service_id },
                    token
                );

                if (slackConnectionStatus.status === 200) {
                    setIsConnected(true);
                }
            };
            fetchData();
        }
    });

    return (
        <article className="min-h-[100vh] flex-1 md:min-h-min">
            <header className="flex items-center justify-between mb-8">
                <h2 className="font-semibold tracking-tight text-xl flex flex-col space-y-1.5">
                    Integrations
                </h2>
            </header>

            <div
                className={
                    'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-7'
                }
            >
                <div
                    className={
                        'flex items-center gap-4 flex-1  md:min-h-min rounded-xl border bg-card text-card-foreground shadow p-3'
                    }
                >
                    <div className={'integrations-list__item-logo'}>
                        <img
                            src={slackLogo}
                            height="60"
                            className={'h-[60px]'}
                            alt="Gandy Administration Panel"
                        />
                    </div>

                    <div className={'flex flex-col gap-3'}>
                        <h3
                            className={
                                'font-semibold tracking-tight text-l flex flex-col space-y-1.5'
                            }
                        >
                            Slack
                        </h3>

                        <p
                            className={
                                'font-normal text-xs text-muted-foreground'
                            }
                        >
                            Get knowledge from your team's Slack workspace.
                        </p>

                        {isConnected ? (
                            <div>
                                <Button size={'sm'}>
                                    <Link to={`/slack/connect`}>Configure</Link>
                                </Button>
                            </div>
                        ) : (
                            <a href="https://slack.com/oauth/v2/authorize?client_id=7462245245889.7359818082054&scope=app_mentions:read,channels:join,chat:write,commands,im:history,links:read,reactions:read,reactions:write,team:read,users:read&user_scope=">
                                <img
                                    alt="Add to Slack"
                                    height="40"
                                    width="139"
                                    src="https://platform.slack-edge.com/img/add_to_slack.png"
                                    srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                                />
                            </a>
                        )}
                    </div>
                </div>

                <div
                    className={
                        'flex items-center gap-4 flex-1 md:min-h-min rounded-xl border bg-card text-card-foreground shadow p-3'
                    }
                >
                    <div className={'integrations-list__item-logo'}>
                        <img
                            className={'h-[60px]'}
                            src={restApiIcon}
                            height="60"
                            alt="Rest API icon"
                        />
                    </div>

                    <div className={'flex flex-col gap-3'}>
                        <h3
                            className={
                                'font-semibold tracking-tight text-l flex flex-col space-y-1.5'
                            }
                        >
                            RestApi
                        </h3>

                        <p
                            className={
                                'font-normal text-xs text-muted-foreground'
                            }
                        >
                            Generate tokens for RestApi. Use them to get
                            knowledge and build own solutions
                        </p>

                        <div>
                            <Button size={'sm'}>
                                <Link to={`/rest-api`}>Tokens</Link>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    );
}
