import * as React from 'react';

export default function GandyLogo() {
    return (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="1024.000000pt"
            height="1024.000000pt"
            viewBox="0 0 1024.000000 1024.000000"
            preserveAspectRatio="xMidYMid meet"
        >
            <g
                transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)"
                fill="#fff"
                stroke="none"
            >
                <path
                    d="M4202 8619 c-119 -59 -340 -350 -474 -623 -53 -110 -58 -149 -20
-154 18 -3 48 23 140 116 156 159 282 242 367 242 33 0 35 -2 55 -67 57 -183
38 -373 -53 -533 -69 -122 -165 -187 -420 -289 -209 -84 -257 -114 -257 -162
0 -49 90 -104 225 -135 105 -25 375 -25 480 -1 38 8 71 14 73 12 17 -17 -122
-201 -200 -266 -107 -89 -282 -158 -400 -159 -46 0 -54 -11 -25 -38 42 -40
136 -75 214 -80 40 -3 73 -9 73 -13 0 -15 -92 -207 -163 -344 -104 -196 -203
-319 -319 -392 l-45 -29 71 -29 c125 -51 204 -152 281 -359 20 -54 34 -100 32
-103 -3 -2 -25 22 -50 54 -89 116 -226 232 -319 272 -44 19 -118 36 -118 27 0
-3 23 -35 50 -72 130 -170 202 -392 250 -764 64 -497 19 -975 -123 -1293 -15
-32 -36 -71 -47 -87 -12 -17 -18 -30 -15 -30 18 0 98 66 160 131 178 188 285
430 331 749 l8 55 7 -50 c13 -90 10 -309 -6 -398 -46 -263 -203 -514 -410
-654 -95 -64 -201 -113 -304 -141 -82 -22 -118 -25 -246 -26 -139 0 -160 2
-276 33 -146 38 -169 35 -169 -23 0 -38 22 -74 127 -204 135 -166 310 -310
482 -396 67 -34 208 -87 271 -103 29 -8 15 -11 -81 -16 -105 -7 -295 3 -373
19 -49 10 -26 -18 50 -58 152 -82 342 -126 585 -135 230 -8 443 18 659 83 473
141 883 398 1202 752 126 141 156 180 254 335 144 227 243 465 302 727 47 210
56 282 57 467 0 94 1 170 3 169 7 -8 44 -152 65 -251 19 -94 22 -141 21 -320
0 -223 -9 -302 -59 -497 -153 -596 -592 -1132 -1190 -1450 -333 -177 -590
-260 -978 -314 -26 -3 -112 -9 -190 -13 -164 -7 -164 -14 0 -55 447 -113 985
-17 1395 247 175 113 369 295 487 456 33 47 61 80 61 75 0 -25 -143 -290 -214
-398 -79 -119 -257 -317 -331 -367 -54 -37 -42 -41 38 -13 287 104 555 323
746 612 140 213 257 531 279 762 l8 85 16 -70 c22 -91 38 -222 38 -307 0 -154
-57 -422 -130 -610 -22 -56 -38 -104 -36 -106 11 -11 146 192 200 303 159 321
212 583 203 999 -5 239 -14 317 -67 591 -82 424 -116 758 -107 1035 12 338 80
600 201 779 20 30 35 55 33 57 -7 8 -106 -47 -154 -85 -99 -78 -216 -261 -259
-406 -10 -33 -22 -64 -27 -70 -15 -15 0 180 19 255 47 189 181 353 319 391
l36 10 -67 72 c-74 80 -193 254 -248 364 -20 40 -36 76 -36 80 0 5 28 9 63 10
74 2 167 34 211 73 36 33 39 31 -89 54 -235 42 -423 208 -581 510 -60 116
-139 325 -127 337 4 4 35 9 67 11 l60 3 15 -40 c8 -22 15 -41 16 -42 3 -6 213
80 255 104 55 31 90 62 90 79 0 37 -330 72 -514 54 -303 -28 -591 -111 -971
-277 -93 -41 -199 -86 -235 -99 -157 -60 -363 -104 -479 -104 l-66 1 115 38
c162 54 326 124 615 263 138 67 296 140 353 164 321 136 683 204 939 176 247
-26 413 -99 413 -181 0 -63 -190 -192 -412 -280 -32 -13 -58 -27 -58 -31 0 -5
14 -33 32 -62 18 -29 33 -54 34 -56 5 -9 145 47 259 103 141 68 228 133 286
209 158 209 -28 412 -451 493 -62 12 -147 17 -316 17 l-232 1 -97 85 c-314
275 -686 482 -963 536 -101 19 -150 18 -200 -7z m1130 -1371 c125 -58 248
-176 361 -348 62 -95 174 -307 165 -315 -1 -1 -51 35 -110 81 -59 46 -110 84
-113 84 -2 0 4 -15 15 -32 33 -57 68 -163 86 -259 21 -115 24 -400 6 -509 -27
-157 -82 -340 -157 -512 -4 -11 -3 -18 4 -18 6 0 11 5 11 11 0 15 81 129 91
129 5 0 9 10 9 23 0 12 5 29 10 37 18 27 12 -10 -25 -153 -94 -373 -301 -751
-616 -1130 -33 -39 -161 -178 -283 -307 -303 -319 -333 -355 -428 -510 -86
-140 -169 -331 -147 -338 5 -2 44 15 87 38 257 137 628 503 838 825 21 33 42
62 45 65 3 3 25 38 49 78 24 39 45 71 47 69 1 -2 -10 -51 -26 -108 -69 -244
-216 -543 -360 -734 -149 -198 -323 -373 -482 -488 -173 -124 -443 -244 -629
-278 -36 -7 -126 -12 -200 -12 -151 0 -248 17 -358 62 -71 28 -198 96 -206
109 -2 4 64 7 147 7 134 0 165 4 262 29 238 61 463 204 606 386 30 38 38 58
48 128 38 257 164 547 324 747 66 82 248 263 372 370 127 108 306 288 388 390
196 243 304 454 351 687 19 93 21 317 4 418 -13 73 -28 94 -28 38 0 -60 -47
-241 -94 -361 -211 -544 -584 -946 -981 -1058 -113 -32 -302 -32 -392 -1 -49
17 -183 87 -183 95 0 1 46 3 103 3 175 2 303 46 434 148 183 143 269 338 280
631 6 162 4 178 -53 475 -34 176 -37 212 -38 365 0 105 4 185 11 209 7 22 11
41 10 43 -8 7 -122 -151 -155 -214 -20 -40 -40 -73 -43 -73 -9 0 -18 51 -26
135 -16 194 63 447 207 660 53 79 65 90 133 129 52 29 56 27 28 -12 -19 -28
-58 -110 -80 -172 -7 -19 -17 -44 -21 -55 -14 -36 -40 -131 -40 -146 0 -8 14
11 31 41 43 75 145 205 207 262 167 156 352 205 504 136z m-1266 -1075 c-7
-62 -57 -310 -82 -408 -24 -95 -77 -245 -86 -245 -4 0 -8 4 -8 9 0 17 -73 103
-110 130 l-37 27 45 49 c25 28 65 80 89 117 47 72 139 254 168 331 10 26 20
47 23 47 2 0 2 -26 -2 -57z"
                />
                <path
                    d="M5134 7081 c-82 -60 -129 -133 -159 -246 -21 -84 -19 -93 13 -49 69
94 173 161 221 142 64 -24 133 -85 185 -162 33 -49 35 -26 5 70 -33 110 -86
190 -163 245 -21 16 -44 29 -51 29 -7 0 -30 -13 -51 -29z"
                />
                <path
                    d="M4785 6395 c5 -111 16 -186 60 -415 62 -327 52 -639 -29 -822 -9 -21
-23 -54 -32 -74 l-15 -37 38 31 c52 42 74 67 122 141 88 138 126 295 111 462
-12 124 -39 222 -123 434 -41 105 -85 224 -97 265 -39 131 -40 132 -35 15z"
                />
                <path
                    d="M7647 7541 c-20 -73 -62 -123 -122 -148 -25 -10 -45 -20 -45 -23 1
-3 23 -16 51 -29 59 -27 106 -86 115 -143 4 -21 10 -38 13 -38 4 0 12 20 19
45 15 56 76 125 133 150 l41 19 -39 15 c-76 31 -104 64 -132 156 l-17 56 -17
-60z"
                />
                <path
                    d="M2669 7417 c-269 -93 -384 -437 -208 -620 44 -46 51 -49 84 -44 71
12 95 56 67 121 -30 67 -35 111 -18 170 19 64 78 124 149 151 188 72 383 -148
291 -328 -9 -17 -52 -69 -96 -115 -161 -168 -169 -221 -114 -717 59 -534 61
-568 45 -573 -8 -3 -35 -26 -61 -53 -33 -33 -52 -64 -62 -100 -18 -60 -20
-173 -5 -238 25 -109 94 -207 172 -244 20 -10 37 -21 37 -25 0 -4 9 -86 20
-182 11 -96 33 -314 50 -485 16 -170 41 -419 55 -553 14 -134 25 -263 25 -288
0 -93 46 -143 105 -116 42 19 42 9 -5 547 -5 61 -23 272 -40 470 -17 198 -34
407 -40 465 -14 154 -15 147 26 160 19 7 38 10 40 7 25 -24 87 -415 144 -897
28 -233 53 -397 64 -407 5 -6 43 141 60 232 42 230 47 639 12 928 -60 488
-195 787 -373 823 l-31 6 -11 107 c-6 58 -25 260 -41 449 -39 431 -43 406 108
565 57 60 93 108 114 152 110 236 17 495 -218 611 -72 36 -84 39 -178 42 -85
2 -112 -1 -167 -21z"
                />
                <path
                    d="M7073 7253 c-21 -38 -39 -71 -41 -75 -2 -3 20 -11 47 -17 199 -42
448 -243 500 -402 10 -33 15 -36 72 -48 34 -7 62 -11 64 -9 1 2 14 26 28 54
15 29 45 70 69 94 73 71 248 140 356 140 23 0 42 3 42 6 0 3 -10 37 -22 75
-27 82 -26 82 -158 39 -163 -53 -308 -146 -349 -224 -8 -14 -17 -26 -20 -26
-3 0 -19 26 -36 58 -41 79 -170 206 -285 282 -94 61 -201 120 -219 120 -5 0
-27 -30 -48 -67z"
                />
                <path
                    d="M6875 7123 c-11 -3 -26 -10 -34 -16 -13 -11 -143 -199 -169 -244 -23
-42 -5 -68 126 -175 331 -270 549 -443 579 -460 42 -26 58 -22 258 62 336 141
510 223 541 254 18 17 79 100 138 185 83 121 103 157 96 171 -14 27 -31 29
-101 15 -152 -32 -392 -136 -508 -219 -54 -38 -72 -46 -109 -46 -79 0 -120 19
-205 97 -140 129 -283 230 -449 317 -111 58 -132 66 -163 59z"
                />
                <path
                    d="M7135 6134 c-82 -42 -153 -101 -192 -160 -26 -39 -51 -63 -82 -79
-149 -75 -250 -296 -298 -645 -21 -159 -24 -593 -5 -755 41 -331 102 -649 171
-887 60 -203 156 -435 195 -467 21 -17 55 -5 60 22 2 12 9 180 15 372 18 561
92 2110 102 2119 3 3 66 17 140 30 206 39 297 75 410 166 69 55 139 141 139
171 0 29 -36 59 -72 59 -17 0 -77 -21 -132 -47 -100 -48 -102 -48 -203 -48
-93 0 -105 2 -125 22 -20 20 -21 26 -10 56 34 94 -12 123 -113 71z"
                />
            </g>
        </svg>
    );
}
