'use client';
// @ts-nocheck
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useObservable } from '@ngneat/react-rxjs';
import { activeService$ } from '../../features/user/user_repository';
import { token$ } from '../../features/auth/auth_repository';
import { createDocument } from '../../features/documents/documents_service';
import { IconContext } from 'react-icons';
import { CiCircleChevLeft } from 'react-icons/ci';
import { updateIsLoading } from '../../features/global_loader/global_loader_service';
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from '../../ui_components/breadcrumbs/breadcrumbs';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '../../ui_components/form/form';
import { Input } from '../../ui_components/input/input';
import { Button } from '../../ui_components/button/button';
import { Textarea } from '../../ui_components/textarea/textarea';

const formSchema = z.object({
    documentName: z.string().nonempty('Username is required'),
    documentUrl: z.string().optional(),
    documentContent: z.string().nonempty('Document content is required'),
});

export function AddDocument() {
    const [token] = useObservable(token$);
    const [activeService] = useObservable(activeService$);
    let params = useParams();
    const navigate = useNavigate();

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            documentName: '',
            documentUrl: '',
            documentContent: '',
        },
    });

    async function onSubmit(values: z.infer<typeof formSchema>) {
        const { documentName, documentUrl, documentContent } = values;

        updateIsLoading(true);
        await createDocument(
            {
                serviceId: activeService.service_id,
                directoryId: params.directoryId,
                name: documentName,
                url: documentUrl,
                content: documentContent,
            },
            token
        );

        updateIsLoading(false);
        return navigate(`/directories/${params.directoryId}`);
    }

    return (
        <article className="flex-1 rounded-xl md:min-h-min  border bg-card text-card-foreground shadow p-6">
            <Breadcrumb>
                <BreadcrumbList>
                    <BreadcrumbItem>
                        <Link to="/admin">Home</Link>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                    <BreadcrumbItem>
                        <Link to={`/directories/${params.directoryId}`}>
                            Directories
                        </Link>
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                    <BreadcrumbItem>
                        <BreadcrumbPage>Add document</BreadcrumbPage>
                    </BreadcrumbItem>
                </BreadcrumbList>
            </Breadcrumb>

            <header className="flex items-center justify-between mb-8 min-h-[40px]">
                <h2 className="font-semibold tracking-tight text-xl flex flex-col space-y-1.5">
                    Add document
                </h2>
            </header>

            <Form {...form}>
                <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="space-y-6 max-w-3xl mx-auto"
                >
                    <FormField
                        control={form.control}
                        name="documentName"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Document name</FormLabel>
                                <FormControl>
                                    <Input {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="documentUrl"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Document url</FormLabel>
                                <FormControl>
                                    <Input {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="documentContent"
                        render={({ field }) => (
                            <FormItem>
                                <FormLabel>Document content</FormLabel>
                                <FormControl>
                                    <Textarea {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <Button type="submit">Submit</Button>
                </form>
            </Form>
        </article>
    );
}
