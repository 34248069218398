// @ts-nocheck
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useObservable } from '@ngneat/react-rxjs';
import { userHasService$ } from '../../features/user/user_repository';
import { useNavigate } from 'react-router-dom';
import { token$ } from '../../features/auth/auth_repository';
import { createService } from '../../features/services/services_service';
import { fetchUser } from '../../features/user/user_service';
import { CiBookmarkCheck } from 'react-icons/ci';
import { IconContext } from 'react-icons';
import { Input } from '../../ui_components/input/input';
import { Button } from '../../ui_components/button/button';
import { Label } from '../../ui_components/label/label';
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from '../../ui_components/card/card';

const PLANS = [
    {
        id: 0,
        name: 'Trial',
        pricing: {
            eur: 0,
            usd: 0,
            pln: 0,
        },
        isFeatured: false,
        description: 'The essentials to provide your best work for clients.',
        features: ['20 questions per month', 'Up to 5 documents'],
    },
    {
        id: 2,
        name: 'Starter',
        pricing: {
            eur: 9,
            usd: 9,
            pln: 39,
        },
        isFeatured: false,
        description: 'The essentials to provide your best work for clients.',
        features: [
            '50 questions per month',
            'Up to 50 documents',
            'Slack integration',
        ],
    },
    {
        id: 3,
        name: 'Team',
        pricing: {
            eur: 49,
            usd: 49,
            pln: 199,
        },
        isFeatured: true,
        description: 'The essentials to provide your best work for clients.',
        features: [
            '500 questions per month',
            'Up to 1000 documents',
            'Slack integration',
        ],
    },
    {
        id: 4,
        name: 'Organization',
        pricing: {
            eur: 79,
            usd: 79,
            pln: 299,
        },
        isFeatured: false,
        description: 'The essentials to provide your best work for clients.',
        features: [
            'Unlimited questions',
            'Unlimited documents',
            'Slack integration',
        ],
    },
];

function Wizard() {
    const [token] = useObservable(token$);
    const [userHasServices] = useObservable(userHasService$);
    const navigate = useNavigate();
    const [serviceName, setServiceName] = useState('');
    const [selectedPlan, setSelectedPlan] = useState(PLANS[2].id);
    const [step, setStep] = useState(1);

    const handleChange = (event) => {
        setServiceName(event.target.value);
    };

    const handleNextStep = (event) => {
        event.preventDefault();
        setStep(2);
    };

    const handleCreateService = async (event) => {
        event.preventDefault();
        await createService(token, serviceName, selectedPlan);
        await fetchUser(token);

        const slackToken = localStorage.getItem('slackToken');
        if (slackToken) {
            localStorage.removeItem('slackToken');
            return navigate(`/slack/connect?t=${slackToken}`);
        }

        return navigate('/admin');
    };

    useEffect(() => {
        if (userHasServices) {
            return navigate('/admin');
        }
    }, [navigate, userHasServices]);

    return (
        <article className="min-h-[100vh] flex-1 rounded-xl md:min-h-min rounded-xl border bg-card text-card-foreground shadow p-6">
            <header className="flex items-center justify-between mb-4">
                <h2 className="font-semibold tracking-tight text-xl flex flex-col space-y-1.5">
                    Welcome to Gandy.ai!
                </h2>
            </header>

            <div className={'mx-auto'}>
                <div className={'font-normal text-base text-muted-foreground '}>
                    <p>
                        We're delighted to have you on board. To kickstart your
                        journey, please create your workspace by giving it a
                        name and choose your plan.
                        <br />
                        This will be your personal area where you can manage
                        knowledge, create directories, upload documents, and
                        more.
                        <br />
                        If you need any help or have questions, feel free to
                        reach out to us. Let's get started!
                    </p>

                    <p>Best wishes, The Gandy.ai Team</p>
                </div>

                {step === 1 && (
                    <form
                        className="flex gap-2 items-end mt-4 "
                        onSubmit={handleNextStep}
                    >
                        <div className="flex-grow">
                            <Label htmlFor="service_name">
                                Create your workspace name
                            </Label>
                            <Input
                                value={serviceName}
                                onChange={handleChange}
                                type="text"
                                placeholder="e.g. My workspace"
                            />
                        </div>

                        <Button size={'sm'}>Next</Button>
                    </form>
                )}
            </div>

            {step === 2 && (
                <div
                    className={
                        'flex flex-col lg:flex-row gap-10 items-stretch mt-8'
                    }
                >
                    {PLANS.map((plan) => (
                        <Card
                            className={`lg:w-2/6 ${plan.id === selectedPlan ? 'border-violet-700' : ''}`}
                        >
                            <CardHeader>
                                <CardTitle>
                                    <p class="text-2xl">{plan.name}</p>
                                </CardTitle>
                                <CardDescription>
                                    <p class="text-base"> {plan.description}</p>
                                </CardDescription>
                            </CardHeader>

                            <CardContent>
                                <p class="mb-4">
                                    <span className="text-4xl font-bold">
                                        {navigator.language === 'pl'
                                            ? new Intl.NumberFormat('pl-PL', {
                                                  style: 'currency',
                                                  currency: 'PLN',
                                              }).format(plan.pricing.pln)
                                            : new Intl.NumberFormat('en-US', {
                                                  style: 'currency',
                                                  currency: 'USD',
                                              }).format(plan.pricing.usd)}
                                    </span>{' '}
                                    / month
                                </p>

                                <Button
                                    size={'lg'}
                                    onClick={(ev) => {
                                        setSelectedPlan(plan.id);
                                        handleCreateService(ev);
                                    }}
                                >
                                    Choose
                                </Button>
                            </CardContent>

                            <CardFooter>
                                <ul className="leading-8">
                                    {plan.features.map((feature) => (
                                        <li className={'flex gap-2'}>
                                            <IconContext.Provider
                                                value={{
                                                    color: '#fff',
                                                    size: '1.5rem',
                                                    title: 'Check',
                                                }}
                                            >
                                                <CiBookmarkCheck aria-label="Check" />
                                            </IconContext.Provider>
                                            {feature}
                                        </li>
                                    ))}
                                </ul>
                            </CardFooter>
                        </Card>
                    ))}
                </div>
            )}
        </article>
    );
}

export default Wizard;
