import { createStore, select, withProps } from '@ngneat/elf';

export const USER_STORE_NAME = 'user';
export const EMPTY_USER = {
    email: '',
    name: '',
    isVerified: false,
    paymentRequired: false,
    services: [],
};

const userStore = createStore({ name: USER_STORE_NAME }, withProps(EMPTY_USER));

export const user$ = userStore.pipe(select((user) => user));
export const userInitials$ = userStore.pipe(
    select((user) =>
        user.name
            .split(' ')
            .map((n) => n[0])
            .join('')
    )
);
export const userHasService$ = userStore.pipe(
    select((user) => user.services.length > 0)
);
export const activeService$ = userStore.pipe(
    select((user) => user.activeService)
);

export function setPaymentRequired(paymentRequired) {
    userStore.update((state) => ({
        ...state,
        paymentRequired,
    }));
}

export const isPaymentRequired$ = userStore.pipe(
    select((user) => user.paymentRequired)
);

export function updateUser(user) {
    const activeIndex = localStorage.getItem('activeService') || 0;

    const actService = user.services.find(
        (service: any) => service.service_id === activeIndex
    );

    const activeService = actService || user.services[0];

    userStore.update((state) => ({
        ...state,
        email: user.email,
        name: user.name,
        isVerified: user.verified,
        services: user.services,
        activeService: activeService,
    }));
}

export function updateActiveService(service) {
    userStore.update((state) => ({
        ...state,
        activeService: service,
    }));
}
