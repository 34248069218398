// @ts-nocheck
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebaseConfig';
import * as React from 'react';
import { updateToken } from '../features/auth/auth_repository';
import { useObservable } from '@ngneat/react-rxjs';
import { isPaymentRequired$, user$ } from '../features/user/user_repository';
import TopNav from '../ui_components/top_nav/top_nav';
import { LoadingScreen } from '../pages/loading_screen/loading_screen';
import { isLoading$ } from '../features/global_loader/global_loader_service';
import { setIsLoading } from '../features/documents/documents_repository';
import { Separator } from '@radix-ui/react-separator';
import {
    SidebarInset,
    SidebarProvider,
    SidebarTrigger,
} from '../ui_components/sidebar/sidebar';
import { AppSidebar } from '../components/sidebar/app_sidebar';
import { useEffect } from 'react';
import Usage from '../ui_components/usage/usage';
import GandyLogo from '../img/gandy_logo';

export const ProtectedRoute = ({ children }) => {
    const [user, loading] = useAuthState(auth);
    const location = useLocation();
    const [userData] = useObservable(user$);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [$user] = useObservable(user$);
    const [$isLoading] = useObservable(isLoading$);
    const [isPaymentRequired] = useObservable(isPaymentRequired$);

    useEffect(() => {
        setIsLoading(true);
    }, [location]);

    useEffect(() => {
        if (loading) return;

        if (!user) {
            if (location.pathname === '/slack/connect') {
                const slackToken = searchParams.get('t');
                localStorage.setItem('slackToken', slackToken);
            }

            return navigate('/');
        }

        if (isPaymentRequired) {
            return navigate('/payment');
        }

        // @ts-ignore
        updateToken(user.accessToken);

        if (userData.email) {
            if (userData.services.length === 0) {
                return navigate('/wizard');
            }
        }

        const slackToken = localStorage.getItem('slackToken');
        if (slackToken) {
            localStorage.removeItem('slackToken');
            return navigate(`/slack/connect?t=${slackToken}`);
        }

        document.querySelector('.main-nav')?.classList.remove('show');
    }, [user, loading, userData, navigate, searchParams]);

    return $user.email ? (
        <>
            <SidebarProvider>
                <AppSidebar />
                <SidebarInset>
                    <header className="flex bg-card h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12 border-b">
                        <div className="flex justify-between content-between items-center gap-2 pl-4">
                            <SidebarTrigger className="-ml-1" />
                        </div>

                        <Separator
                            orientation="vertical"
                            className={
                                'shrink-0 bg-border w-[1px] h-8 mr-1 md:hidden'
                            }
                        />

                        <div className={'md:hidden flex items-center gap-2'}>
                            <div className=" flex aspect-square size-8 items-center justify-center rounded-lg bg-sidebar-primary text-sidebar-primary-foreground">
                                <GandyLogo />
                            </div>
                            <div className="grid flex-1 text-left text-sm leading-tight">
                                <span className="truncate font-semibold">
                                    Gandy.ai
                                </span>
                            </div>
                        </div>
                    </header>
                    <div className="flex flex-col gap-4 p-4 md:p-6">
                        {children}
                    </div>
                </SidebarInset>
            </SidebarProvider>

            {$isLoading && (
                <div className={'global-loader'}>
                    <div className={'loader'}></div>
                </div>
            )}
        </>
    ) : (
        <LoadingScreen />
    );
};

export default ProtectedRoute;
