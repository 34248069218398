//@ts-nocheck

import * as React from 'react';
import { useEffect, useState } from 'react';
import {
    createDirectory,
    getDirectories,
} from '../../features/directories/directories_service';
import { useObservable } from '@ngneat/react-rxjs';
import { token$ } from '../../features/auth/auth_repository';
import { activeService$ } from '../../features/user/user_repository';
import { directories$ } from '../../features/directories/directories_repository';
import {
    Description,
    Dialog,
    DialogPanel,
    DialogTitle,
} from '@headlessui/react';
import { Link, useNavigate } from 'react-router-dom';
import { IconContext } from 'react-icons';
import { CiCirclePlus, CiCircleRemove } from 'react-icons/ci';
import { setCurrentPage } from '../../features/documents/documents_repository';
import { Button } from '../../ui_components/button/button';
import { Separator } from '../../ui_components/separator/separator';
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from '../../ui_components/alert_dialog/alert_dialog';
import { Input } from '../../ui_components/input/input';

function DirectoriesList() {
    const [token] = useObservable(token$);
    const [activeService] = useObservable(activeService$);
    const [directories] = useObservable(directories$);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newDirectoryName, setNewDirectoryName] = useState('');
    const [isDirectoryCreating, setIsDirectoryCreating] = useState(false);
    const navigate = useNavigate();

    const createNewDirectory = async (event) => {
        event.preventDefault();

        setIsModalOpen(false);
        setIsDirectoryCreating(true);
        const directory = await createDirectory(
            {
                name: newDirectoryName,
                serviceId: activeService.service_id,
            },
            token
        );
        setIsDirectoryCreating(false);

        if (directory) {
            setCurrentPage(1);
            return navigate(`/directories/${directory.id}`);
        }
    };

    const showCreateNewDirectoryModal = () => {
        setIsModalOpen(true);
    };

    useEffect(() => {
        async function fetchData() {
            if (activeService) {
                await getDirectories(
                    {
                        serviceId: activeService.service_id,
                    },
                    token
                );
            }
        }
        fetchData();
    }, [activeService, token]);

    return (
        <article className="flex-1 rounded-xl md:min-h-min rounded-xl border bg-card text-card-foreground shadow p-6">
            <section className="section">
                <header className="flex items-center justify-between mb-8">
                    <h2 className="font-semibold tracking-tight text-xl flex flex-col space-y-1.5">
                        Directories
                        {directories.length <= 0 && (
                            <p className="font-normal text-base text-muted-foreground">
                                To start working with Gandy.ai, you need to
                                create at least one directory to store your
                                documents.
                                <br />
                                Start by clicking "Create new directory" in the
                                right corner of this section.
                            </p>
                        )}
                    </h2>
                    <aside>
                        <Button onClick={showCreateNewDirectoryModal}>
                            Create new directory
                        </Button>
                    </aside>
                </header>

                <ul className="grid gap-6">
                    {directories.map((directory) => (
                        <li
                            className="flex w-full items-center justify-between space-x-4 [&:not(:last-child)]:border-b pb-6"
                            key={directory.id}
                        >
                            <Link
                                onClick={() => setCurrentPage(1)}
                                to={`/directories/${directory.id}`}
                                className="space-y-2"
                            >
                                <h3 className="text-base font-medium leading-none">
                                    {directory.name}
                                </h3>
                                <p className="text-sm text-muted-foreground">
                                    {directory.documents} documents
                                </p>

                                {/*<Separator className="my-4" />*/}
                            </Link>

                            <Link
                                onClick={() => setCurrentPage(1)}
                                to={`/directories/${directory.id}`}
                            >
                                <Button variant="outline">
                                    View documents
                                </Button>
                            </Link>
                        </li>
                    ))}

                    {isDirectoryCreating && (
                        <li className="directory-list__box directory-list__box_new">
                            <div className={'loader'}></div>
                        </li>
                    )}
                </ul>
            </section>

            <AlertDialog open={isModalOpen}>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>
                            {`Enter directory name`}
                        </AlertDialogTitle>
                        <AlertDialogDescription>
                            This will create new directory
                        </AlertDialogDescription>
                    </AlertDialogHeader>

                    <form
                        className="flex gap-2 items-stretch"
                        onSubmit={createNewDirectory}
                    >
                        <Input
                            type={'text'}
                            onChange={(e) =>
                                setNewDirectoryName(e.target.value)
                            }
                            onKeyDown={(e) => {
                                if (e.key === 'Escape') {
                                    setNewDirectoryName(e.target.value);
                                    createNewDirectory();
                                }
                            }}
                        />
                        <Button>Create new</Button>
                    </form>
                </AlertDialogContent>
            </AlertDialog>
        </article>
    );
}

export default DirectoriesList;
