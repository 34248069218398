import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { auth, sendPasswordReset } from './firebaseConfig';
import {
    CardDescription,
    CardHeader,
    CardTitle,
    Card,
    CardContent,
} from './ui_components/card/card';
import { Label } from './ui_components/label/label';
import { Input } from './ui_components/input/input';
import { Button } from './ui_components/button/button';

function Reset() {
    const [email, setEmail] = useState('');
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) return;
        if (user) navigate('/admin');
    }, [user, loading, navigate]);

    return (
        <div className="w-dvw h-dvh flex flex-col items-center justify-center">
            <div className={'flex flex-col gap-6'}>
                <Card>
                    <CardHeader>
                        <CardTitle className="text-2xl">
                            Reset password
                        </CardTitle>
                        <CardDescription>
                            Enter your email below to to reset your password.
                        </CardDescription>
                    </CardHeader>
                    <CardContent>
                        <div className="flex flex-col gap-6">
                            <div className="grid gap-2">
                                <Label htmlFor="email">Email</Label>
                                <Input
                                    id={'email'}
                                    type="text"
                                    className="reset__textBox"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="E-mail Address"
                                />
                            </div>
                            <Button
                                className="w-full"
                                type="button"
                                onClick={() => sendPasswordReset(email)}
                            >
                                Send password reset email
                            </Button>

                            <div>
                                Back to{' '}
                                <Link className={'text-purple-400'} to="/">
                                    login page
                                </Link>{' '}
                            </div>

                            {/*<div>*/}
                            {/*    Don't have an account?{' '}*/}
                            {/*    <Link*/}
                            {/*        className={'text-purple-400'}*/}
                            {/*        to="/register"*/}
                            {/*    >*/}
                            {/*        Register*/}
                            {/*    </Link>{' '}*/}
                            {/*    now.*/}
                            {/*</div>*/}
                        </div>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
}

export default Reset;
