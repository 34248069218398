'use client';

import * as React from 'react';
import { ChevronsUpDown, Plus } from 'lucide-react';
import {
    SidebarMenu,
    SidebarMenuButton,
    SidebarMenuItem,
    useSidebar,
} from '../../ui_components/sidebar/sidebar';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuShortcut,
    DropdownMenuTrigger,
} from '../../ui_components/dropdown_menu/dropdown_menu';
import {
    activeService$,
    updateActiveService,
    user$,
} from '../../features/user/user_repository';
import { setCurrentPage } from '../../features/documents/documents_repository';
import { useObservable } from '@ngneat/react-rxjs';
import { useNavigate } from 'react-router-dom';
import GandyLogo from '../../img/gandy_logo';
import { ScrollArea } from '../../ui_components/scroll_area/scroll_area';

export function TeamSwitcher({ services }: any) {
    const { isMobile } = useSidebar();
    const [user] = useObservable(user$);
    const [activeService] = useObservable(activeService$);

    const navigate = useNavigate();
    const handleChangeService = (servicedId: any) => {
        localStorage.setItem('activeService', servicedId);
        const actService = user.services.find(
            (service: any) => service.service_id === servicedId
        );

        updateActiveService(actService);
        setCurrentPage(1);
        return navigate(`/`);
    };

    return (
        <SidebarMenu>
            <SidebarMenuItem>
                <DropdownMenu modal={false}>
                    <DropdownMenuTrigger asChild>
                        <SidebarMenuButton
                            size="lg"
                            className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
                        >
                            <div className="flex aspect-square size-8 items-center justify-center rounded-lg bg-sidebar-primary text-sidebar-primary-foreground">
                                <GandyLogo />
                            </div>
                            <div className="grid flex-1 text-left text-sm leading-tight">
                                <span className="truncate font-semibold">
                                    Gandy.ai
                                </span>
                                <span className="truncate text-xs">
                                    {activeService && activeService.name}
                                </span>
                            </div>
                            <ChevronsUpDown className="ml-auto" />
                        </SidebarMenuButton>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent
                        className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
                        align="start"
                        side={isMobile ? 'bottom' : 'right'}
                        sideOffset={4}
                    >
                        <DropdownMenuLabel className="text-xs text-muted-foreground">
                            Services
                        </DropdownMenuLabel>

                        <ScrollArea className="h-[80dvh]">
                            {services.map((service: any, index: number) => (
                                <DropdownMenuItem
                                    key={service.service_id}
                                    onClick={() => {
                                        handleChangeService(service.service_id);
                                    }}
                                    className="gap-2 p-2"
                                >
                                    {service.name}
                                </DropdownMenuItem>
                            ))}
                        </ScrollArea>

                        <DropdownMenuSeparator />
                        <DropdownMenuItem className="gap-2 p-2">
                            <div className="flex size-6 items-center justify-center rounded-md border bg-background">
                                <Plus className="size-4" />
                            </div>
                            <div className="font-medium text-muted-foreground">
                                Add workspace
                            </div>
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu>
            </SidebarMenuItem>
        </SidebarMenu>
    );
}
