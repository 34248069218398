import { useObservable } from '@ngneat/react-rxjs';
import { token$ } from './features/auth/auth_repository';
import { useEffect } from 'react';
import { fetchUser } from './features/user/user_service';
import Routing from './router/routing';

function App() {
    const [token] = useObservable(token$);

    useEffect(() => {
        if (token) {
            fetchUser(token);
        }
    }, [token]);

    return <Routing />;
}

export default App;
