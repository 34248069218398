'use client';

// @ts-nocheck
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getQuestionsStatsRaport } from '../../features/raports/questions_raport_service';
import { useObservable } from '@ngneat/react-rxjs';
import { token$ } from '../../features/auth/auth_repository';
import { activeService$ } from '../../features/user/user_repository';

import { directories$ } from '../../features/directories/directories_repository';
import { getDirectories } from '../../features/directories/directories_service';
import { Bar, BarChart, CartesianGrid, XAxis } from 'recharts';
import {
    ChartConfig,
    ChartContainer,
    ChartLegend,
    ChartLegendContent,
    ChartTooltip,
    ChartTooltipContent,
} from '../../ui_components/chart/chart';
import { Button } from '../../ui_components/button/button';
import { addDays, format } from 'date-fns';
import { CalendarIcon } from 'lucide-react';
import { DateRange } from 'react-day-picker';

import { Calendar } from '../../ui_components/calendar/calendar';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '../../ui_components/popover/popover';
import { cn } from '../../lib/utils';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '../../ui_components/select/select';
import { Label } from '../../ui_components/label/label';

const chartConfig = {
    desktop: {
        label: 'Numer of questions',
        color: '#6d28d9',
    },
} satisfies ChartConfig;

export function QuestionsUsage() {
    const [token] = useObservable(token$);
    const [activeService] = useObservable(activeService$);
    const [questions, setQuestions] = useState([]);
    const [directories] = useObservable(directories$);

    const [isDateOpen, setIsDateOpen] = useState(false);
    const [date, setDate] = React.useState<DateRange | undefined>({
        from: new Date(new Date().setDate(new Date().getDate() - 31)),
        to: new Date(),
    });

    useEffect(() => {
        const fetchData = async () => {
            const questionStatsRaportData = await getQuestionsStatsRaport(
                {
                    serviceId: activeService.service_id,
                    params: {
                        date_from: date?.from?.toISOString(),
                        date_to: date?.to?.toISOString(),
                    },
                },
                token
            );

            setQuestions(questionStatsRaportData);

            if (activeService) {
                if (directories.length <= 0) {
                    await getDirectories(
                        {
                            serviceId: activeService.service_id,
                        },
                        token
                    );
                }
            }
        };

        if (activeService) {
            fetchData();
        }
    }, [activeService, date, token]);

    const [aggregate, setAggregate] = useState('2');
    const [directoryId, setDirectoryId] = useState('all');

    const submitForm = async (ev) => {
        ev.preventDefault();

        const params = {
            date_from: date?.from?.toISOString(),
            date_to: date?.to?.toISOString(),
            aggregate,
        };

        if (directoryId && directoryId !== 'all') {
            params.directory_id = directoryId;
        }

        const questionStatsRaportData = await getQuestionsStatsRaport(
            {
                serviceId: activeService.service_id,
                params,
            },
            token
        );

        setQuestions(questionStatsRaportData);
    };

    return (
        <article className="min-h-[100vh] flex-1 rounded-xl md:min-h-min rounded-xl border bg-card text-card-foreground shadow p-6">
            <header className="flex items-center justify-between mb-8">
                <h2 className="font-semibold tracking-tight text-xl flex flex-col space-y-1.5">
                    Questions usage raport
                </h2>
            </header>

            <form
                className={'flex gap-3 items-end mb-4'}
                onSubmit={(ev) => submitForm(ev)}
            >
                <div>
                    <Label htmlFor={'date-range'}>
                        Select date
                        <div className={cn('grid gap-2')}>
                            <Popover
                                open={isDateOpen}
                                onOpenChange={setIsDateOpen}
                            >
                                <PopoverTrigger asChild>
                                    <Button
                                        id="date"
                                        variant={'outline'}
                                        className={cn(
                                            'w-[300px] justify-start text-left font-normal',
                                            !date && 'text-muted-foreground'
                                        )}
                                    >
                                        <CalendarIcon />
                                        {date?.from ? (
                                            date.to ? (
                                                <>
                                                    {format(
                                                        date.from,
                                                        'LLL dd, y'
                                                    )}{' '}
                                                    -{' '}
                                                    {format(
                                                        date.to,
                                                        'LLL dd, y'
                                                    )}
                                                </>
                                            ) : (
                                                format(date.from, 'LLL dd, y')
                                            )
                                        ) : (
                                            <span>Pick a date</span>
                                        )}
                                    </Button>
                                </PopoverTrigger>
                                <PopoverContent
                                    className="w-auto p-0"
                                    align="start"
                                >
                                    <Calendar
                                        initialFocus
                                        mode="range"
                                        defaultMonth={date?.from}
                                        selected={date}
                                        onDayClick={(day: Date) => {
                                            setDate((prev) => {
                                                if (prev?.to) {
                                                    // If 'to' is already set, reset the range
                                                    return {
                                                        from: day,
                                                        to: undefined,
                                                    };
                                                } else if (prev?.from) {
                                                    // If 'from' is set and 'to' is not
                                                    if (day < prev.from) {
                                                        // If the new day is before the 'from' date, reset the range

                                                        return {
                                                            from: day,
                                                            to: undefined,
                                                        };
                                                    } else {
                                                        // Otherwise, set the 'to' date
                                                        const newDate = {
                                                            from: prev.from,
                                                            to: day,
                                                        };

                                                        setDate(newDate);
                                                        setIsDateOpen(false);

                                                        return newDate;
                                                    }
                                                } else {
                                                    // If neither 'from' nor 'to' is set, set 'from'
                                                    return {
                                                        from: day,
                                                        to: undefined,
                                                    };
                                                }
                                            });
                                        }}
                                        numberOfMonths={2}
                                    />
                                </PopoverContent>
                            </Popover>
                        </div>
                    </Label>
                </div>

                <div>
                    <Label htmlFor={'aggregate'}>
                        Present as
                        <Select
                            onValueChange={(value) => {
                                setAggregate(value);
                            }}
                            defaultValue={aggregate}
                        >
                            <SelectTrigger>
                                <SelectValue placeholder="Select a verified email to display" />
                            </SelectTrigger>

                            <SelectContent>
                                <SelectItem value="2">daily</SelectItem>
                                <SelectItem value="3">weekly</SelectItem>
                                <SelectItem value="4">monthly</SelectItem>
                            </SelectContent>
                        </Select>
                    </Label>
                </div>

                <div>
                    <Label htmlFor={'aggregate'}>
                        Directory
                        <Select
                            onValueChange={(value) => {
                                setDirectoryId(value);
                            }}
                            defaultValue={directoryId}
                        >
                            <SelectTrigger>
                                <SelectValue placeholder="Select a verified email to display" />
                            </SelectTrigger>

                            <SelectContent>
                                <SelectItem value="all">all</SelectItem>
                                {directories.map((directory) => (
                                    <SelectItem
                                        key={directory.id}
                                        value={directory.id}
                                    >
                                        {directory.name}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </Label>
                </div>

                <Button>Show</Button>
            </form>

            <ChartContainer
                config={chartConfig}
                className="min-h-[200px] max-h-[500px] w-full"
            >
                <BarChart accessibilityLayer data={questions}>
                    <CartesianGrid vertical={false} />
                    <XAxis
                        dataKey="key"
                        tickLine={false}
                        tickMargin={10}
                        axisLine={false}
                        tickFormatter={(value) =>
                            new Intl.DateTimeFormat('en-US', {
                                dateStyle: 'medium',
                            }).format(new Date(value))
                        }
                    />
                    <ChartTooltip
                        labelFormatter={(value) =>
                            new Intl.DateTimeFormat('en-US', {
                                dateStyle: 'medium',
                            }).format(new Date(value))
                        }
                        content={<ChartTooltipContent />}
                    />
                    <ChartLegend content={<ChartLegendContent />} />
                    <Bar
                        dataKey="count"
                        fill="var(--color-desktop)"
                        radius={4}
                    />
                </BarChart>
            </ChartContainer>
        </article>
    );
}
