// @ts-nocheck
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getQuestionsRaport } from '../../features/raports/questions_raport_service';
import { useObservable } from '@ngneat/react-rxjs';
import { token$ } from '../../features/auth/auth_repository';
import { activeService$ } from '../../features/user/user_repository';
import { getDirectories } from '../../features/directories/directories_service';
import { directories$ } from '../../features/directories/directories_repository';
import { IconContext } from 'react-icons';
import { CiCircleChevLeft, CiCircleChevRight } from 'react-icons/ci';
import { DateRange } from 'react-day-picker';
import { cn } from '../../lib/utils';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '../../ui_components/popover/popover';
import { Button } from '../../ui_components/button/button';
import { CalendarIcon } from 'lucide-react';
import { format } from 'date-fns';
import { Calendar } from '../../ui_components/calendar/calendar';
import { Label } from '../../ui_components/label/label';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '../../ui_components/select/select';
import { Input } from '../../ui_components/input/input';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '../../ui_components/table/table';

export function QuestionsRaport() {
    const [token] = useObservable(token$);
    const [activeService] = useObservable(activeService$);
    const [questions, setQuestions] = useState([]);
    const [directories] = useObservable(directories$);

    const [isDateOpen, setIsDateOpen] = useState(false);
    const [date, setDate] = React.useState<DateRange | undefined>({
        from: new Date(new Date().setDate(new Date().getDate() - 31)),
        to: new Date(),
    });

    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            const questionRaportData = await getQuestionsRaport(
                {
                    serviceId: activeService.service_id,

                    params: {
                        date_from: date.from.toISOString(),
                        date_to: date.to.toISOString(),
                        page,
                        limit,
                    },
                },
                token
            );

            setPages(questionRaportData.pages);
            setQuestions(questionRaportData.list);

            if (activeService) {
                if (directories.length <= 0) {
                    await getDirectories(
                        {
                            serviceId: activeService.service_id,
                        },
                        token
                    );
                }
            }
        };

        if (activeService) {
            fetchData();
        }
    }, [activeService, token, page, limit, date, directories.length]);

    const [directoryId, setDirectoryId] = useState('all');

    const handleChangePage = async (page) => {
        setPage(page);
    };

    const submitForm = async (ev) => {
        ev.preventDefault();

        const params = {
            date_from: date.from.toISOString(),
            date_to: date.to.toISOString(),
        };

        if (directoryId && directoryId !== 'all') {
            params.directory_id = directoryId;
        }

        const questionRaportData = await getQuestionsRaport(
            {
                serviceId: activeService.service_id,
                params,
            },
            token
        );

        setQuestions(questionRaportData.list);
    };

    return (
        <article className="min-h-[100vh] flex-1 rounded-xl md:min-h-min rounded-xl border bg-card text-card-foreground shadow p-6">
            <header className="flex items-center justify-between mb-8">
                <h2 className="font-semibold tracking-tight text-xl flex flex-col space-y-1.5">
                    Questions
                </h2>
            </header>

            <form
                onSubmit={(ev) => submitForm(ev)}
                className={'flex gap-3 items-end mb-4'}
            >
                <div className={'form__control form__control_inline'}>
                    <Label htmlFor={'date-range'}>
                        Select date
                        <div className={cn('grid gap-2')}>
                            <Popover
                                open={isDateOpen}
                                onOpenChange={setIsDateOpen}
                            >
                                <PopoverTrigger asChild>
                                    <Button
                                        id="date"
                                        variant={'outline'}
                                        className={cn(
                                            'w-[300px] justify-start text-left font-normal',
                                            !date && 'text-muted-foreground'
                                        )}
                                    >
                                        <CalendarIcon />
                                        {date?.from ? (
                                            date.to ? (
                                                <>
                                                    {format(
                                                        date.from,
                                                        'LLL dd, y'
                                                    )}{' '}
                                                    -{' '}
                                                    {format(
                                                        date.to,
                                                        'LLL dd, y'
                                                    )}
                                                </>
                                            ) : (
                                                format(date.from, 'LLL dd, y')
                                            )
                                        ) : (
                                            <span>Pick a date</span>
                                        )}
                                    </Button>
                                </PopoverTrigger>
                                <PopoverContent
                                    className="w-auto p-0"
                                    align="start"
                                >
                                    <Calendar
                                        initialFocus
                                        mode="range"
                                        defaultMonth={date?.from}
                                        selected={date}
                                        onDayClick={(day: Date) => {
                                            setDate((prev) => {
                                                if (prev?.to) {
                                                    // If 'to' is already set, reset the range
                                                    return {
                                                        from: day,
                                                        to: undefined,
                                                    };
                                                } else if (prev?.from) {
                                                    // If 'from' is set and 'to' is not
                                                    if (day < prev.from) {
                                                        // If the new day is before the 'from' date, reset the range

                                                        return {
                                                            from: day,
                                                            to: undefined,
                                                        };
                                                    } else {
                                                        // Otherwise, set the 'to' date
                                                        const newDate = {
                                                            from: prev.from,
                                                            to: day,
                                                        };

                                                        setDate(newDate);
                                                        setIsDateOpen(false);

                                                        return newDate;
                                                    }
                                                } else {
                                                    // If neither 'from' nor 'to' is set, set 'from'
                                                    return {
                                                        from: day,
                                                        to: undefined,
                                                    };
                                                }
                                            });
                                        }}
                                        numberOfMonths={2}
                                    />
                                </PopoverContent>
                            </Popover>
                        </div>
                    </Label>
                </div>

                <div>
                    <Label htmlFor={'aggregate'}>
                        Directory
                        <Select
                            onValueChange={(value) => {
                                setDirectoryId(value);
                            }}
                            defaultValue={directoryId}
                        >
                            <SelectTrigger>
                                <SelectValue placeholder="Select a verified email to display" />
                            </SelectTrigger>

                            <SelectContent>
                                <SelectItem value="all">all</SelectItem>
                                {directories.map((directory) => (
                                    <SelectItem
                                        key={directory.id}
                                        value={directory.id}
                                    >
                                        {directory.name}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </Label>
                </div>

                <Button>Show</Button>
            </form>

            <div className={'rounded-md border'}>
                <Table>
                    <TableHeader>
                        <TableRow className={'border-b-0'}>
                            <TableHead
                                className={'pt-2 pb-2 border-0'}
                                colSpan="99"
                            >
                                <form
                                    className={
                                        'flex items-center gap-2 justify-end'
                                    }
                                >
                                    <div>
                                        <label
                                            className={
                                                'flex items-center gap-2'
                                            }
                                            htmlFor={'limit'}
                                        >
                                            Show:
                                            <Select
                                                value={limit}
                                                onValueChange={(value) => {
                                                    setLimit(value);

                                                    setCurrentPage(1);
                                                }}
                                            >
                                                <SelectTrigger>
                                                    <SelectValue>
                                                        {limit}
                                                    </SelectValue>
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectItem value={10}>
                                                        10
                                                    </SelectItem>
                                                    <SelectItem value={20}>
                                                        20
                                                    </SelectItem>
                                                    <SelectItem value={50}>
                                                        50
                                                    </SelectItem>
                                                </SelectContent>
                                            </Select>
                                        </label>
                                    </div>

                                    <div className={'flex items-center gap-2 '}>
                                        {page > 1 && (
                                            <div
                                                onClick={() => {
                                                    handleChangePage(page - 1);
                                                }}
                                            >
                                                <IconContext.Provider
                                                    value={{
                                                        color: '#fff',
                                                        size: '1.5rem',
                                                        title: 'Actions',
                                                    }}
                                                >
                                                    <span className="btn-icon">
                                                        <CiCircleChevLeft aria-label="Previous page" />
                                                    </span>
                                                </IconContext.Provider>
                                            </div>
                                        )}
                                        <div>
                                            <label
                                                className={
                                                    'flex items-center gap-2'
                                                }
                                                htmlFor={'limit'}
                                            >
                                                Page:
                                                <Input
                                                    type={'number'}
                                                    value={page}
                                                    max={pages}
                                                    min={1}
                                                    onChange={(ev) =>
                                                        setCurrentPage(
                                                            ev.target.value
                                                        )
                                                    }
                                                />
                                            </label>
                                        </div>
                                        of {pages}
                                        {page < pages && (
                                            <div
                                                onClick={() => {
                                                    handleChangePage(page + 1);
                                                }}
                                            >
                                                <IconContext.Provider
                                                    value={{
                                                        color: '#fff',
                                                        size: '1.5rem',
                                                        title: 'Actions',
                                                    }}
                                                >
                                                    <span className="btn-icon">
                                                        <CiCircleChevRight aria-label="Next page" />
                                                    </span>
                                                </IconContext.Provider>
                                            </div>
                                        )}
                                    </div>
                                </form>
                            </TableHead>
                        </TableRow>
                        <TableRow lassName={'border-t-0'}>
                            <TableHead>Question</TableHead>
                            <TableHead>Answer</TableHead>
                            <TableHead>Date</TableHead>
                        </TableRow>
                    </TableHeader>

                    <TableBody>
                        {questions.map((question, index) => (
                            <TableRow key={index}>
                                <TableCell>{question.question}</TableCell>
                                <TableCell
                                    dangerouslySetInnerHTML={{
                                        // eslint-disable-next-line no-undef
                                        __html: marked.parse(question.answer),
                                    }}
                                ></TableCell>

                                <TableCell>
                                    {new Intl.DateTimeFormat('en-US').format(
                                        new Date(question.timestamp)
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>

                    <tfoot>
                        <TableRow className={'border-0'}>
                            <TableCell colSpan="99">
                                <form
                                    className={
                                        'flex items-center gap-2 justify-end'
                                    }
                                >
                                    <div>
                                        <label
                                            className={
                                                'flex items-center gap-2'
                                            }
                                            htmlFor={'limit'}
                                        >
                                            Show:
                                            <Select
                                                value={limit}
                                                onValueChange={(value) => {
                                                    setLimit(value);

                                                    setCurrentPage(1);
                                                }}
                                            >
                                                <SelectTrigger>
                                                    <SelectValue>
                                                        {limit}
                                                    </SelectValue>
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectItem value={10}>
                                                        10
                                                    </SelectItem>
                                                    <SelectItem value={20}>
                                                        20
                                                    </SelectItem>
                                                    <SelectItem value={50}>
                                                        50
                                                    </SelectItem>
                                                </SelectContent>
                                            </Select>
                                        </label>
                                    </div>

                                    <div className={'flex items-center gap-2 '}>
                                        {page > 1 && (
                                            <div
                                                onClick={() => {
                                                    handleChangePage(page - 1);
                                                }}
                                            >
                                                <IconContext.Provider
                                                    value={{
                                                        color: '#fff',
                                                        size: '1.5rem',
                                                        title: 'Actions',
                                                    }}
                                                >
                                                    <span className="btn-icon">
                                                        <CiCircleChevLeft aria-label="Previous page" />
                                                    </span>
                                                </IconContext.Provider>
                                            </div>
                                        )}
                                        <div>
                                            <label
                                                className={
                                                    'flex items-center gap-2'
                                                }
                                                htmlFor={'limit'}
                                            >
                                                Page:
                                                <Input
                                                    type={'number'}
                                                    value={page}
                                                    max={pages}
                                                    min={1}
                                                    onChange={(ev) =>
                                                        setCurrentPage(
                                                            ev.target.value
                                                        )
                                                    }
                                                />
                                            </label>
                                        </div>
                                        of {pages}
                                        {page < pages && (
                                            <div
                                                onClick={() => {
                                                    handleChangePage(page + 1);
                                                }}
                                            >
                                                <IconContext.Provider
                                                    value={{
                                                        color: '#fff',
                                                        size: '1.5rem',
                                                        title: 'Actions',
                                                    }}
                                                >
                                                    <span className="btn-icon">
                                                        <CiCircleChevRight aria-label="Next page" />
                                                    </span>
                                                </IconContext.Provider>
                                            </div>
                                        )}
                                    </div>
                                </form>
                            </TableCell>
                        </TableRow>
                    </tfoot>
                </Table>
            </div>
        </article>
    );
}
